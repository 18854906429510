import { t } from "i18next";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { CasinoIconGameType } from "../../../common/types/Casino/CasinoIconGameType";
import { useTypeSelector } from "../../../hooks/useTypeSelector";
import { CasinoInfo } from "../../../services";

import "./CasinoCart.scss";
import {
  setFavoritsUser,
  setFavoritsLocalSelect,
  setFavoritsLocalDelete,
} from "../../../store/reducers/favoritsReducer";
import {
  setModalPopap,
  setModalLogin,
} from "../../../store/reducers/modalsReducer";
import { useParams } from "react-router-dom";

interface CasinoCart {
  cartInfo: CasinoIconGameType;
  checkedGame: number;
  setCheckedGame: React.Dispatch<React.SetStateAction<number>>;
}

const CasinoCart = ({ cartInfo, checkedGame, setCheckedGame }: CasinoCart) => {
  const { en } = useParams();
  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  const favoritsGamesUser = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoUser
  );
  const favoritsGamesLocal = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoLocal
  );
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const handleCheckGame = () => {
    setCheckedGame(cartInfo.id);
  };
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      setCheckedGame(-1);
    }
  };
  const { user } = useTypeSelector((data) => data.accountInfoReducer);
  const token = localStorage.getItem("token");

  function openOutside(url: string) {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      window.location.href = url;
    } else {
      window.open(url, "_blank");
    }
  }

  async function searchPlay() {
    try {
      const gameUrl = `/${cartInfo.game_provider.slug}/${cartInfo.slug}?currency=TRY&lang=${en}&platform=desktop&game_link=true`;
      const res = token
        ? await CasinoInfo.casinoPlay(gameUrl)
        : await CasinoInfo.casinoPlayDemo(gameUrl);
      const url = res.data.game_link;
      let hasError = 0;
      if (res.data.error) {
        hasError = Object.keys(res.data.error).length;
      }
      if (
        // user.extra_data.softswiss_freespin &&
        user.active_bonus?.settings.freespin_games &&
        res.data.has_freespins
      ) {
        const launchCode = res.data.launch_code;
        const freespinGames = user.active_bonus.settings.freespin_games;

        if (
          freespinGames.some((game: string | string[]) =>
            game.includes(launchCode)
          )
        ) {
          await CasinoInfo.casinoPlayFreespins(launchCode);
          if (url) {
            openOutside(url);
          } else if (hasError) {
            // If there is no game link and there is an error
            alert(res.data.error.message);
          }
        } else {
          if (url) {
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
              window.location.href = url;
            } else {
              window.open(url, "_blank");
            }
          } else if (hasError) {
            // If there is no game link and there is an error
            alert(res.data.error.message);
          }
        }
      } else {
        if (url) {
          if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            window.location.href = url;
          } else {
            window.open(url, "_blank");
          }
        } else if (hasError) {
          // If there is no game link and there is an error
          alert(res.data.error.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchPlayDemo() {
    try {
      const res = await CasinoInfo.casinoPlayDemo(
        `/${cartInfo.game_provider.slug}/${cartInfo.slug}?currency=TRY&lang=${en}&platform=desktop&game_link=true`
      );

      const url = res.data.game_link;

      if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        window.location.href = url;
      } else {
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error("Ошибка при получении игровой ссылки:", error);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  const handleOpenDemo = () => {
    searchPlayDemo();
  };

  const handleOpenPlay = () => {
    if (role === "player") {
      searchPlay();
    } else {
      dispatch(setModalPopap(true));
      dispatch(setModalLogin(true));
    }
  };

  const handleFavoriteUserSelect = () => {
    async function gandleSelectFavorit() {
      try {
        await CasinoInfo.setFavoriteGame({
          game_id: cartInfo.id,
          type: 1,
        });
        const res = await CasinoInfo.favoriteGames();
        dispatch(setFavoritsUser(res.data));
      } catch (e) {
        console.error(e);
      }
    }
    async function gandleRemoveFavorit() {
      await CasinoInfo.setFavoriteGame({
        game_id: cartInfo.id,
        type: 0,
      });
      const res = await CasinoInfo.favoriteGames();
      dispatch(setFavoritsUser(res.data));
    }
    favoritsGamesUser.some((obj) => obj.id === cartInfo.id)
      ? gandleRemoveFavorit()
      : gandleSelectFavorit();
  };
  const handleFavoriteLocalSelect = () => {
    async function gandleSelectFavorit() {
      dispatch(
        setFavoritsLocalSelect({
          id: cartInfo.id,
          slug: cartInfo.slug,
          original_name: cartInfo.original_name,
          is_demo_enabled: cartInfo.is_demo_enabled,
          game_provider: {
            slug: cartInfo.game_provider.slug,
          },
          banner: cartInfo.banner,
        })
      );
    }
    async function gandleRemoveFavorit() {
      dispatch(
        setFavoritsLocalDelete({
          id: cartInfo.id,
          slug: cartInfo.slug,
          original_name: cartInfo.original_name,
          is_demo_enabled: cartInfo.is_demo_enabled,
          game_provider: {
            slug: cartInfo.game_provider.slug,
          },
          banner: cartInfo.banner,
        })
      );
    }
    favoritsGamesLocal.some((obj) => obj.id === cartInfo.id)
      ? gandleRemoveFavorit()
      : gandleSelectFavorit();
  };

  return (
    <div className="casino-game-item-content" onClick={handleCheckGame}>
      <div className="casino-game-item active " data-badge="">
        <img
          src={cartInfo.banner.cloudflare}
          className="casino-game-item-image-bc"
          alt={cartInfo.original_name}
          title={cartInfo.original_name}
          loading="eager"
        />
        {checkedGame === cartInfo.id ? (
          <div className="casino-game-item-block" ref={wrapperRef}>
            <div className="casino-game-icons">
              <i className="casino-game-info-icon bc-i-info" />
            </div>
            <h3 className="casino-game-item-label-bc">
              {cartInfo.original_name}
            </h3>
            <div className="casino-game-buttons">
              <button
                className="btn a-color "
                type="button"
                title="Play"
                onClick={handleOpenPlay}
              >
                <span>{t("casino.Play")}</span>
              </button>
              {cartInfo.is_demo_enabled ? (
                <button
                  className="btn a-outline "
                  type="button"
                  title="Demo"
                  onClick={handleOpenDemo}
                >
                  <span>{t("casino.Demo")}</span>
                </button>
              ) : null}
            </div>
          </div>
        ) : null}
        {role === "player" ? (
          <i
            className={`casino-game-item-fav-bc bc-i-favorite ${
              favoritsGamesUser.some((obj) => obj?.id === cartInfo?.id)
                ? "active"
                : "null"
            }`}
            onClick={handleFavoriteUserSelect}
          />
        ) : (
          <i
            className={`casino-game-item-fav-bc bc-i-favorite ${
              favoritsGamesLocal.some((obj) => obj?.id === cartInfo?.id)
                ? "active"
                : "null"
            }`}
            onClick={handleFavoriteLocalSelect}
          />
        )}
      </div>
    </div>
  );
};

export { CasinoCart };
